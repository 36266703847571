const COLOR = {
  navColor: '#3f5db3',
  buttonColor: '#afa394',
  textColor: '#333743'
}

const BREAK_POINT = {
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1440
}

const HOST_URL = 'http://jx.tlanx.com';

export {
  COLOR,
  BREAK_POINT,
  HOST_URL
}
