import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @keyframes line-scale {
      0% {
          transform: scaley(1);
      }
      50% {
          transform: scaley(0.4);
      }
      100% {
          transform: scaley(1);
      }
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderInner = styled.div`
  div {
    display: inline-block;
    width: 5px;
    height: 50px;
    border-radius: 2px;
    margin: 2px 5px;
    animation-fill-mode: both;
    background-color: '#2B3C43';
    &:nth-child(1) {
      animation: line-scale 1s 0.1s infinite cubic-bezier(.2, .68, .18, 1.08);
    }
    &:nth-child(2) {
      animation: line-scale 1s 0.2s infinite cubic-bezier(.2, .68, .18, 1.08);
    }
    &:nth-child(3) {
      animation: line-scale 1s 0.3s infinite cubic-bezier(.2, .68, .18, 1.08);
    }
    &:nth-child(4) {
      animation: line-scale 1s 0.4s infinite cubic-bezier(.2, .68, .18, 1.08);
    }
    &:nth-child(5) {
      animation: line-scale 1s 0.5s infinite cubic-bezier(.2, .68, .18, 1.08);
    }
  }
`;

const Loading = () => (
  <Loader>
    <GlobalStyle />
    <LoaderInner>
      {
        Array.from({length:5}).map((v, k) => <div key={k}></div>)
      }
    </LoaderInner>
  </Loader>
);

export default Loading;