import React, {useState} from 'react';
import styled from 'styled-components';
import { Route, Link } from 'react-router-dom';

import {COLOR, BREAK_POINT} from '../helper/const';

const NavButton = styled.div`
  @media (min-width: ${BREAK_POINT.xl - 1}px) {
    display: none;
  }
  position: absolute;
  top: 10px;
  left: 15px;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: 40px;
  z-index: 1;
  color: ${props => props.theme === 'black' ? COLOR.textColor : '#fff'};
  cursor: pointer;
  i {
    display: inline-block;
    width: 50%;
    height: 2px;
    vertical-align: middle;
    background-color: ${props => props.active ? 'transparent' : 'currentColor'};
    &::before,
    &::after {
      content: '';
      display: block;
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: -8px;
      background-color: currentColor;
      transform-origin: -4%;
      transition: all .2s;
    }
    &::before {
      ${props => props.active && (`
        transform: rotate(45deg);
      `)}
    }
    &::after {
      top: auto;
      bottom: -6px;
      ${props => props.active && (`
        transform: rotate(-45deg);
      `)}
    }
  }
  &:hover {
    i {
      &::before {
        top: ${props => props.active ? '-8px' : '-9px'};
      }
      &::after {
        bottom: ${props => props.active ? '-6px' : '-7px'};
      }
    }
  }
`;

const NavList = styled.ul`
  display: ${props => props.active ? 'block' : 'none'};
  position: absolute;
  left: 30px;
  top: 0;
  ${props => props.active && `
    position: static;
  `}
  @media (min-width: ${BREAK_POINT.xl}px) {
    display: block;
  }
`;

const NavItem = styled.li`
  display: inline-block;
  margin-right: 5px;
  padding: 30px 10px 17px;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: ${props => props.active ? '#3f5db3' : ''};
  &:last-child {
    margin-right: 0;
  }
  a {
    color: ${props => props.active || props.theme === 'white' ? '#fff' : '#333'};
    text-decoration: none;
  }
`;

const NavWrapper = styled.nav`
  ${props => props.active && `
    position: fixed;
    top: 0;
    bottom: 0;
    width: 240px;
    z-index: 9;
    background-color: ${COLOR.textColor};
    box-shadow: 3px 0 3px 3px rgba(0, 0, 0, .3);
    ${NavItem} {
      display: block;
      padding: 20px 10px;
      margin-right: 0;
      a {
        color: #fff;
      }
    }
    ${NavButton} {
      color: #fff;
    }
    ${NavList} {
      margin-top: 60px;
    }
  `}
`;

const NAV_CONFIG = [
  {
    text: '生产数智',
    url: '/produce'
  },
  {
    text: '采购数智',
    url: '/purchase'
  },
  {
    text: '库房数智',
    url: '/store'
  },
  {
    text: '销售数智',
    url: '/sales'
  },
  {
    text: '质量数智',
    url: '/quality'
  },
  {
    text: '企业案例',
    url: '/case'
  },
  {
    text: '联系我们',
    url: '/contact'
  }
];

const NavLink = ({ label, to, activeOnlyWhenExact, theme, setShowNav }) => {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <NavItem theme={theme} key={label} active={match} onClick={() => setShowNav(false)}>
          <Link to={to}>{label}</Link>
        </NavItem>
      )}
    />
  );
}


const Nav = props => {
  const [showNav, setShowNav] = useState(false);
  return (
    <NavWrapper active={showNav}>
      <NavButton active={showNav} theme={props.theme} onClick={() => setShowNav(!showNav)}><i /></NavButton>
      <NavList active={showNav}>
        {
          NAV_CONFIG.map(item => (
            <NavLink theme={props.theme} key={item.url} activeOnlyWhenExact={item.url === '/'} to={item.url} label={item.text} setShowNav={setShowNav} />
          ))
        }
      </NavList>
    </NavWrapper>
  )
}

export default Nav;