import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {debounce} from 'lodash';

import {COLOR, BREAK_POINT} from '../helper/const';
import Nav from './Nav';
import defaultLogo from '../assets/images/logo.png';

const Logo = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  align-self: center;
  height: 60px;
  @media (max-width: ${BREAK_POINT.sm}px) {
    margin-top: 8px;
    height: 50px;
  }
`;

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${props => props.mask && COLOR.textColor};
  min-height: ${props => props.mask ? '60px' : 'auto'};
  box-shadow: ${props => props.mask ? '0 3px 3px 3px rgba(0, 0, 0, .3)' : ''};
  ${Logo} {
    margin-top: ${props => props.mask && '8px'};
  }
`;

const Welcome = styled.div`
  position: fixed;
  top: 5px;
  right: 15px;
  width: 190px;
  text-align: left;
  color: ${props => props.theme === 'black' ? COLOR.textColor : '#fff'};
  h4 {
    display: none;
    margin: 0;
    text-align: right;
    line-height: 55px;
    font-size: 1.3rem;
  }
  @media (max-width: ${BREAK_POINT.md}px) {
    h3, p {
      display: none;
    }
    h4 {
      display: block;
    }
  }
  strong {
    margin-right: 10px;
    color: ${props => props.theme === 'black' ? '#000' : '#fff'};
  }
  span {
    margin-left: 10px;
    margin-right: 10px;
    color: ${props => props.theme === 'black' ? '#000' : '#fff'};
  }
  p {
    margin-top: 5px;
    line-height: 1.4;
    opacity: .7;
    color: ${props => props.theme === 'black' ? '#000' : '#fff'};
  }
  a {
    text-decoration: none;
    color: #ccc;
  }
`;

const Header = props => {
  const [mask, setMask] = useState(false);
  const handleHeaderMask = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setMask(scrollTop > 60);
  }
  useEffect(() => {
    document.addEventListener('scroll', debounce(handleHeaderMask, 50));
  }, [])
  return (
    <HeaderWrapper mask={mask}>
      <Nav theme={props.theme} />
      {
        !props.hideLogo && <Link to="/"><Logo src={defaultLogo} alt="精效数智" /></Link>
      }
      <Welcome theme={props.theme}>
        <h3><strong><a href="http://223.94.138.139:11111" target="_blank" rel="noopener noreferrer">体验</a></strong>|<span><Link to="/contact">邀约讲解</Link></span>|<span><Link to="/about">关于我们</Link></span></h3>
        <h4><span><Link to="/about">关于我们</Link></span></h4>
        { !mask && <p>如果您对我们很感兴趣，可以前来参观我们的数据化中心，以便获得更多的帮助</p>}
      </Welcome>
    </HeaderWrapper>
  )
}

export default Header;