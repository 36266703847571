import React from 'react';
import styled from 'styled-components';
import {BREAK_POINT, COLOR} from '../helper/const';

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 10px;
  left: 30px;
  z-index: 1;
  ${props => props.theme === 'white' && `
    opacity: .2;
    color: #fff;
  `}
  @media (max-width: ${BREAK_POINT.lg}px) {
    position: static;
    background-color: ${COLOR.textColor};
    opacity: 1;
    padding: 10px 0;
  }
`;

const Footer = props => {
  return (
    <FooterWrapper theme={props.theme}>
      Copyright &copy; 2019 www.jx-erp All rights reserved. 浙ICP备19049833号
    </FooterWrapper>
  )
}

export default Footer;