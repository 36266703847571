import React, {useState} from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import styled from 'styled-components';

import {BREAK_POINT} from './helper/const';
import loadable from './helper/loadable';
import Header  from './components/Header';
import Footer  from './components/Footer';

const Produce = loadable(()=>import('./page/Produce/'));
const Contact = loadable(()=>import('./page/Contact/'));
const Purchase = loadable(()=>import('./page/Purchase/'));
const Sales = loadable(()=>import('./page/Sales/'));
const Store = loadable(()=>import('./page/Store/'));
const Quality = loadable(()=>import('./page/Quality/'));
const Case = loadable(()=>import('./page/Case/'));
const About = loadable(()=>import('./page/About/'));


const Main =styled.main`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @media (max-width: ${BREAK_POINT.lg}px) {
    position: static;
  }
`;

const ThemeWrapper = styled.div`

`;

const Router = () => {
  const [theme, setTheme] = useState('black');
  const [hideLogo, setHideLogo] = useState(false);
  return (
    <BrowserRouter>
      <ThemeWrapper theme={theme}>
        <Header theme={theme} hideLogo={hideLogo} />
        <Main>
          <Route exact path="/" render={() => <Produce onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/produce" render={() => <Produce onChangeTheme={setTheme} />} />
          <Route path="/purchase" render={() => <Purchase onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/store" render={() => <Store onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/sales" render={() => <Sales onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/quality" render={() => <Quality onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/case" render={() => <Case onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/contact" render={() => <Contact onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
          <Route path="/about" render={() => <About onChangeTheme={setTheme} hideLogo={setHideLogo} />} />
        </Main>
        <Footer theme={theme} />
      </ThemeWrapper>
    </BrowserRouter>
  );
}

export default Router;